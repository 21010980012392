<template>
  <el-drawer
      title="选择商品SKU"
      :visible.sync="drawer"
      :direction="direction"
      append-to-body
      :before-close="handleClose">
    <div class="query-items">
      <el-input v-model="query.SKUCode" size="mini" class="mr15" clearable style="width: 20rem" placeholder="SKU编码"></el-input>
      <el-select class="mr15" placeholder="商品品类" size="mini" v-model="query.ProductId" clearable style="width: 20rem">
          <el-option
              v-for="item in productOption"
              :key="item.Id"
              :label="item.Name"
              :value="item.Id">
          </el-option>
        </el-select>
      <el-button type="primary" class="" size="small" icon="el-icon-search" @click="load">查询</el-button>
    </div>
    <div class="infinite-list" v-infinite-scroll="load" style="overflow:auto">
      <el-checkbox-group v-model="checkedSKU" @change="handleCheckedSKUChange">
        <el-card class="box-card" v-for="item in listData" :key="item.Id">
          <el-checkbox :label="item.Id">
            <div class="item u-start-flex">
              <div style="width: 30rem">SKU编码：{{ item.SKUCode }}</div>
              <div>{{ item.Name }}</div>
            </div>
            <div class="item u-start-flex">
              <div style="width: 30rem">商品品类：{{ item.ProductName }}</div>
              <div>{{ item.GoodsTypeName }}</div>
            </div>
          </el-checkbox>
        </el-card>
      </el-checkbox-group>
    </div>
    <div style="padding: 0 20px" class="u-between-flex">
      <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">
        全选 已选中：{{ checkedSKU.length }}
      </el-checkbox>
      <el-button @click="submitCheckBox" type="primary" size="small">提交</el-button>
    </div>
  </el-drawer>
</template>

<script>
import {getCODProduct, getCodSKU} from "@/api/cod";

export default {
  props: {
    drawer: {
      type: Boolean,
      default: false
    },
    SKUIds: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      direction: 'rtl',
      checkedSKU: [],
      listData: [],
      checkAll: false,
      isIndeterminate: true,
      query: {},
      productOption: []
    }
  },
  computed: {
    checkedSKUList() {
      return this.listData.filter(item => {
        return this.checkedSKU.includes(item.Id)
      })
    }
  },
  watch: {
    SKUIds: {
      handler() {
        this.load()
      },
      immediate: true
    }
  },
  mounted() {
    this.getCodProduct()
  },
  methods: {
    handleCheckedSKUChange(val) {
      let checkedCount = val.length;
      this.checkAll = checkedCount === this.listData.length;
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.listData.length;
    },
    handleCheckAllChange(val) {
      this.checkedSKU = val ? this.listData.map(item => { return item.Id }) : [];
      this.isIndeterminate = false
    },
    handleClose() {
      this.$emit('close')
    },
    submitCheckBox() {
      if (!this.checkedSKU.length) {
        this.$message.warning("请选择SKU")
      } else {
        this.$emit("handleCheck", this.checkedSKUList)
      }
    },
    getCodProduct() {
      const data = {
        PageIndex: 1,
        PageRows: 100,
      }
      getCODProduct(data).then(response => {
        const { Code, Msg, Data } = response
        if (Code === 200) {
          this.productOption = Data
        } else {
          this.$message.warning(Msg)
        }
      })
    },
    load () {
      const data = {
        PageIndex: 1,
        PageRows: 99,
        QueryCodeType: 14,
        QueryCodes: this.query.SKUCode ? [this.query.SKUCode] : undefined,
        ProductId: this.query.ProductId || undefined
      }
      getCodSKU(data).then(response => {
        const { Code, Msg, Data } = response
        if (Code === 200) {
          this.listData = Data.filter(item => {
            return !this.SKUIds.includes(item.Id)
          })
          this.checkedSKU = []
          this.checkAll = false
        } else {
          this.$message.warning(Msg)
        }
      })
    },
  }
}

</script>

<style scoped lang="scss">
::v-deep #el-drawer__title {
  font-size: 16px;
}
.query-items {
  width: 95%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  //justify-content: space-between;
  align-items: center;
}
.infinite-list {
  width: 95%;
  height: 68rem;
  margin: 0 auto;
  .box-card {
    margin: 10px 0;
    .item {
      font-size: 12px;
    }
  }
}
</style>